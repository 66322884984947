import React, { useContext } from 'react'
import { Container, Sticky } from '@kogk/common'
import { Link } from '@kogk/gatsby-theme-base'
import cn from 'classnames'
import { MobileMenuContext } from '@root/src/context/mobileMenuContext'
import { Icon } from '@islandsstofa/react'
import useGetLogo from '@root/src/hooks/useGetLogo'

const Logo = () => {
  const logo = useGetLogo() ?? 'visitIcelandLogo'

  const { close: closeMobileMenu } = useContext(MobileMenuContext)
  return (
    <Link to='/' onClick={() => closeMobileMenu()}>
      <Icon containerClass='d-none d-lg-block logo pt-3' type={logo} />
      <Icon containerClass='d-block d-lg-none logo pt-2' type={logo} />
    </Link>
  )
}

const NavBar = () => {
  const { isOpen } = useContext(MobileMenuContext)

  return (
    <Sticky initialHeight={88}>
      <nav
        className={cn('navbar', {
          'navbar--mobile-dropdown-open': isOpen
        })}
      >
        <Container>
          <div className='d-flex navbar__item-wrap align-items-center justify-content-between'>
            <div>
              <Logo />
            </div>
          </div>
        </Container>
      </nav>
    </Sticky>
  )
}

export default NavBar
