import Img, { FluidObject } from 'gatsby-image'
import React from 'react'

type ImageType = {
  url: string
  alt: string
  fluid: FluidObject
}

export type CompanyAndRepresentativeModalTemplateProps = {
  image: ImageType
  secondaryImage?: ImageType
  title: string
  subtitle: string
  text: {
    html: string
  }
  links: {
    url: string
    label: string
  }[]
}

const CompanyAndRepresentativeModalTemplate = ({
  content
}: {
  content: CompanyAndRepresentativeModalTemplateProps
}) => {
  return (
    <div className='d-c-b-modal-template d-lg-flex modal-template__add-some-pad'>
      <div className='col-lg-5'>
        <div className='d-c-b-modal-template__image border completeCenter image-rounded mt-4 mb-2 mt-lg-0'>
          <Img
            className='h-100 w-100'
            imgStyle={{ objectFit: 'cover' }}
            {...content.image}
          />
        </div>
      </div>
      <div className='col-lg-7'>
        <div className='d-c-b-modal-template__secondary-image image-rounded mb-2'>
          {content.secondaryImage && (
            <Img
              className='h-100 w-100'
              imgStyle={{ objectFit: 'contain' }}
              {...content.secondaryImage}
            />
          )}
        </div>

        <h4>{content.title}</h4>
        <p className='parag--lg mb-2'>{content.subtitle}</p>
        <div
          className='pr-lg-2 mb-2'
          dangerouslySetInnerHTML={{ __html: content.text.html }}
        />
        {content.links.map((link, key) => (
          <div key={key} className='article mb-2'>
            <span className='inline-link'>
              <a href={link.url} target='_blank'>
                {link.label}
              </a>
            </span>
          </div>
        ))}
      </div>
    </div>
  )
}

export default CompanyAndRepresentativeModalTemplate
