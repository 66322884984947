import React, { useMemo, useEffect, useContext } from 'react'
import { useLocation } from '@reach/router'
import { navigate } from 'gatsby'
import { SearchContext } from '@root/src/context/searchContext'
import { parseQueryString } from '@src/utils/search'
import { Container } from '@kogk/common'
import { ButtonDep as Button, Input, ModalContext } from '@islandsstofa/react'
import { MobileMenuContext } from '@root/src/context/mobileMenuContext'
import SearchResultsUi from '../SearchResultsUi'

const SearchResults = () => {
  return (
    <Container className='mt-3 mt-lg-5'>
      <div className='offset-lg-2 col-lg-8'>
        <Search isModal={false} showResultsBelow />
      </div>
    </Container>
  )
}

export default SearchResults

export const Search: React.FC<{
  isModal?: boolean
  isMobile?: boolean
  showResultsBelow?: boolean
}> = ({ isModal, isMobile, showResultsBelow }) => {
  const { search: queryString } = useLocation()
  const { q: qraw = '' } = parseQueryString(queryString)
  const q = qraw.trim()

  const { closeModal } = useContext(ModalContext)
  const { close: closeMobileMenu } = useContext(MobileMenuContext)

  const { searchTerm, addSearchTerm, clearSearchTerm } = useContext(
    SearchContext
  )

  const runSearch = () => {
    // do not run search on empty values or single characters
    if (searchTerm && searchTerm.length > 1) {
      const newPath = `/search-results/?q=${encodeURIComponent(searchTerm)}`

      navigate(newPath, { replace: true })

      if (isModal) {
        closeModal()
      }
      if (isMobile) {
        closeMobileMenu()
      }
    }
  }

  useEffect(() => {
    clearSearchTerm()
  }, [])

  return (
    <div className='w-100'>
      {!isMobile && (
        <h3>{isModal ? 'Search our site' : `Search results for "${q}"`}</h3>
      )}
      <div className='pt-3 pb-2 pb-md-3'>
        <Input
          placeholder='Input your search keywords.'
          containerClass='col-md-10 px-0'
          className='w-100'
          type='text'
          onKeyUp={e => {
            if (e.key !== 'Enter') {
              return
            }

            runSearch()
          }}
          onChange={e => addSearchTerm((e.target as HTMLInputElement).value)}
          appendIcon='search'
          onRenderButton={() => (
            <Button
              onClick={() => runSearch()}
              type='primary'
              className='mt-2 m-md-0 ml-md-3 d-none d-lg-block'
              label='Search'
            ></Button>
          )}
        />
      </div>
      {useMemo(
        () =>
          q.length > 0 && (
            <SearchResultsUi
              className={showResultsBelow ? 'd-block' : 'd-none'}
              term={q}
            />
          ),
        [q]
      )}
    </div>
  )
}
