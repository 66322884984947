import { graphql, useStaticQuery } from 'gatsby'
import '@src/prismic/fragments/seo'

export interface MetaInterface {
  title: string
  keywords: string
  image: {
    url: string
  }
  description: string
}

export const useGetSeoProperties = (): MetaInterface => {
  const data = useStaticQuery(graphql`
    {
      prismicSeo {
        ...seoFragmentFull
      }
    }
  `)

  return {
    ...data.prismicSeo.data
  }
}

export default useGetSeoProperties
