import React from 'react'
import { useGetFooter } from '@src/hooks'
import { FooterUi, IconType } from '@islandsstofa/react'

const Footer = () => {
  const d: { logoType: IconType; [propName: string]: any } = {
    logoType: 'businessIcelandLogo',
    en: true,
    ...useGetFooter()
  }
  if (!d) {
    return null
  }

  return <FooterUi {...d} />
}

export default Footer
