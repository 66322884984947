import { graphql as gql, useStaticQuery } from 'gatsby'
import '@src/prismic/fragments/menu'
import { IconType } from '@islandsstofa/react'

interface PrismicMenuRaw {
  data: {
    logo_name: keyof IconType
  }
}

export default () => {
  const data: {
    prismicMenu: PrismicMenuRaw
  } = useStaticQuery(gql`
    {
      prismicMenu {
        ...fragmentPrismicMenu
      }
    }
  `)

  if (!data) {
    return null
  }

  return data.prismicMenu.data.logo_name
}
